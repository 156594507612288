import React from 'react';
import styled from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby'

import { usePageContext } from '../context/pageContext';

import Typing from 'react-typing-animation';

const StyledBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(90deg, rgba(70,152,142,1) 0%, rgba(92,174,103,1) 49%, rgba(138,197,65,1) 100%);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-size: 200% 200%;

  -webkit-animation: Gradient 12s ease infinite;
  -moz-animation: Gradient 12s ease infinite;
  -o-animation: Gradient 12s ease infinite;
  animation: Gradient 12s ease infinite;

  @-webkit-keyframes Gradient {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
  }
  @-moz-keyframes Gradient {
      0%{background-position:0% 51%}
      50%{background-position:100% 50%}
      100%{background-position:0% 51%}
  }
  @-o-keyframes Gradient {
      0%{background-position:0% 51%}
      50%{background-position:100% 50%}
      100%{background-position:0% 51%}
  }
  @keyframes Gradient {
      0%{background-position:0% 51%}
      50%{background-position:100% 50%}
      100%{background-position:0% 51%}
  }

  @media (min-device-width : 768px) and (max-device-width : 1024px) {
    height: 600px;
  }

  @media (min-device-width : 320px) and (max-device-width : 667px) {
    height: 400px;
    padding: 50px 0;
    margin: 200px 0 0 0;
  }
`

const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto auto;

  @media (min-device-width : 320px) and (max-device-width : 667px) {
    width: 250px;
  }
`

const StyledQuoteGroup = styled.div`
  color: ${props => props.theme.colors.white};
  margin-bottom: 130px;
  position: relative;
`

const StyledQuoteSign = styled.p`
  font-family: ${props => props.theme.fonts.overpass};
  margin: 0;
  font-size: 50px;
  font-weight: bold;
  position: absolute;

  &:first-of-type {
    top: -35px;
    left: -13px;

    @media (min-device-width : 320px) and (max-device-width : 667px) {
      top: -42px;
    }
  }

  &:last-of-type {
    bottom: -43px;
    right: -14px;
    transform: rotate(180deg);

    @media (min-device-width : 320px) and (max-device-width : 667px) {
      right: 77px;
    }
  }
`

const StyledQuote = styled.p`
  font-size: 30px;
  margin: 0;
  & span {
    font-family: ${props => props.theme.fonts.overpass};
    font-weight: bold;
  }
`

const StyledLine = styled.div`
  width: 70px;
  border-top: 1px solid ${props => props.theme.colors.white};
`

const StyledAuthor = styled.p`
  font-size: 25px;
  color: ${props => props.theme.colors.white};
`

const AgencyIntroCard = () => {
  const {langKey: currentLang} = usePageContext();

  const data = useStaticQuery(graphql`
  query Quote {
    agencyIntroJson {
      quote {
        en
        es
        textQuote
        textQuoteTwo
      }
    }
  }
`)

  return (
    <StyledBackground>
      <StyledText>
        <StyledQuoteGroup>
            <StyledQuoteSign>“</StyledQuoteSign>
            <Typing speed={45}>
              <StyledQuote>
                {data.agencyIntroJson.quote[currentLang]}
              </StyledQuote>
            </Typing>
            <StyledQuoteSign>“</StyledQuoteSign>
        </StyledQuoteGroup>
        <StyledLine />
        <StyledAuthor>{data.agencyIntroJson.quote.textQuote}</StyledAuthor>
        <StyledAuthor>{data.agencyIntroJson.quote.textQuoteTwo}</StyledAuthor>
      </StyledText>
    </StyledBackground>
  )
}

export default AgencyIntroCard;
