import React from 'react';
import styled from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

import { usePageContext } from '../context/pageContext';

const StyledAgencyIntroCardBig = styled.div`
  width: 100%;
  margin: 0 auto;
  height: calc(100vh - 150px);
  display: flex;
  align-content: center;
  justify-content: center;
  padding-top: 150px;

  @media (min-device-width : 320px) and (max-device-width : 667px) {
    display: none;
  }
`

const StyledAgencyIntroCardSmall = styled.div`
  display: none;

  @media (min-device-width : 320px) and (max-device-width : 667px) {
    display: flex;
    flex-direction: column;
    align-content: center;
    padding-top: 150px;
  }
`

const StyledPhotoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;


  & .img {
    max-width: 800px;
    max-height: 90%;
    margin-left: -85%;

    @media (min-device-width : 768px) and (max-device-width : 1024px) {
      max-height: 70%;
      margin-left: -35%;
      align-self: center;
    }

    @media (min-device-width : 320px) and (max-device-width : 667px) {
      margin: -18% auto -10% auto;
      max-width: 80%;
    }
  }
`

const StyledText = styled.div`
  flex-basis: 70%;
  z-index: 2;

  @media (min-device-width : 768px) and (max-device-width : 1024px) {
    align-self: center;
  }

  @media (min-device-width : 320px) and (max-device-width : 667px) {
    display: flex;
    flex-direction: column;
    align-content: space-between;
  }

  & h1 {
    font-family: ${props => props.theme.fonts.baskerville};
    font-size: 80px;
    white-space: nowrap;
    // color: ${props => props.theme.colors.green};
    font-weight: normal;
    margin-left: 12%;
    margin-bottom: 0;

    /* Fallback: Set a background color. */
    background-color: red;

    /* Create the gradient. */
    background-image: ${props => props.theme.colors.blue};

    /* Set the background size and repeat properties. */
    background-size: 200%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

    animation: shine 8s linear infinite;
    @keyframes shine {
      0%{background-position:0% 51%}
      50%{background-position:100% 50%}
      100%{background-position:0% 51%}
    }

    @media (min-device-width : 768px) and (max-device-width : 1024px) {
      font-size: 52px;
      margin-left: 15%;
    }

    @media (min-device-width : 400px) and (max-device-width : 667px) {
      font-size: 38px;
      text-align: center;
      margin: 0;
    }

    @media (min-device-width : 200px) and (max-device-width : 399px) {
      font-size: 28px;
      text-align: center;
      margin: 0;
    }
  }

  & p {
  font-size: 20px;
  margin: 10px 15% 0 40%;

    @media (min-device-width : 768px) and (max-device-width : 1024px) {
      margin: 10px 15% 0 30%;
    }

    @media (min-device-width : 320px) and (max-device-width : 667px) {
      text-align: center;
      margin: 0;
      padding: 0 10%;
    }
  }
`

const AgencyIntroCard = () => {
  const {langKey: currentLang} = usePageContext();

  const data = useStaticQuery(graphql`
    query AgencyIntro {
      agencyIntroJson {
        sloganFirst {
          en
          es
        }
        sloganSecond {
          en
          es
        }
        sloganThird {
          en
          es
        }
        text {
          en
          es
        }
      }
    }
  `)

  return (
    <>
      <StyledAgencyIntroCardBig data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease" data-sal-duration="1000">
        <StyledText>
          <h1>
            {data.agencyIntroJson.sloganFirst[currentLang]}<br />
            {data.agencyIntroJson.sloganSecond[currentLang]}<br />
            {data.agencyIntroJson.sloganThird[currentLang]}.
          </h1>
          <p>{data.agencyIntroJson.text[currentLang]}</p>
        </StyledText>
        <StyledPhotoContainer>
          <StaticImage src="../images/tech.webp" alt="By Mayckon Giovani" placeholder="blurred" width={277} height={414} className="img"/>
        </StyledPhotoContainer>
      </StyledAgencyIntroCardBig>

      <StyledAgencyIntroCardSmall data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease" data-sal-duration="1000">
        <StyledText>
          <h1>
            {data.agencyIntroJson.sloganFirst[currentLang]}<br />
            {data.agencyIntroJson.sloganSecond[currentLang]}<br />
            {data.agencyIntroJson.sloganThird[currentLang]}.
          </h1>
        </StyledText>
        <StyledPhotoContainer>
        <StaticImage src="../images/tech.webp" alt="By Mayckon Giovani" placeholder="blurred" width={277} height={414} className="img"/>
        </StyledPhotoContainer>
        <StyledText>
          <p>{data.agencyIntroJson.text[currentLang]}</p>
        </StyledText>
      </StyledAgencyIntroCardSmall>
    </>
  )
}

export default AgencyIntroCard;

