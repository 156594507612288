import React, {useState} from 'react';
import styled from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby'

import { usePageContext } from '../context/pageContext';

import LetsTalk from './LetsTalk';

const StyledContactForm = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const StyledEmpty = styled.div`
  width: 10%;

  @media (min-device-width : 320px) and (max-device-width : 667px) {
    display: none;
  }
`

const StyledText = styled.div`
  margin-right: ${props => props.out ? "-6%" : "null"};
  margin-left: -6%;

  @media (min-device-width : 768px) and (max-device-width : 1024px) {
    margin-left: 2%;
  }

  @media (min-device-width : 320px) and (max-device-width : 667px) {
    margin: 0 auto;
  }
`

const StyledLetsTalk = styled.p`
  display: none;

  @media (min-device-width : 320px) and (max-device-width : 667px) {
    display: block;
    text-align: center;
    margin-top: 100px;
    font-family: ${props => props.theme.fonts.baskerville};
    font-weight: bold;
    font-size: 50px;
    // color: ${props => props.theme.colors.green};

    /* Fallback: Set a background color. */
    background-color: green;

    /* Create the gradient. */
    background-image: ${props => props.theme.colors.blue};

    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }
`

const StyledEmailText = styled.p`
  margin: ${props => props.about ? "180px" : "150px"} auto 50px auto;
  width: 350px;
  text-align: center;

  & a {
    font-family: ${props => props.theme.fonts.baskerville};
    // color: ${props => props.theme.colors.green};
    font-size: 1.5rem;
    font-weight: bold;
    display: inline-block;
    margin: 10px 0;

    /* Fallback: Set a background color. */
    background-color: green;

    /* Create the gradient. */
    background-image: ${props => props.theme.colors.blue};

    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

    &:hover{
      text-decoration: underline;
    }
  }

  @media (min-device-width : 320px) and (max-device-width : 667px) {
    margin-top: 0;
    width: 320px;
    text-align: center;
  }
`

const StyledForm = styled.form`
  max-width: 550px;
  margin: 0 auto 100px auto;

  @media (min-device-width : 320px) and (max-device-width : 667px) {
    width: 320px;
    margin: 0 auto 70px auto;
  }
`

const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
`

const StyledNameGroup = styled.div`
  display: flex;
  justify-content: space-between;
  & > * {
    width: 40%;
  }
`

const StyledLabel = styled.label`
  margin-bottom: 7px;
`

const StyledInput = styled.input`
  border: none;
  height: 25px;
  margin-bottom: 9px;
  font-family: ${props => props.theme.fonts.roboto};
  font-weight: 300;
  font-size: 12px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
`

const StyledTextarea = styled.textarea`
  border: none;
  margin-bottom: 9px;
  font-family: ${props => props.theme.fonts.roboto};
  font-weight: 300;
  font-size: 12px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
`

const StyledUnderline = styled.div`
  width: 100%;
  border-bottom: 2px solid ${props => props.theme.colors.white};
`

const StyledSendButton = styled.button`
  width: 100%;
  height: 35px;
  background: ${props => props.theme.colors.blue};
  color: ${props => props.theme.colors.black};
  border: none;
  font-family: ${props => props.theme.fonts.roboto};
  font-weight: 300;
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
`

const ContactForm = ({about}) => {
  const {langKey: currentLang} = usePageContext();

  const [contactFormHovered, setContactFormHovered] = useState(false);

  const hoveringHandler = () => {
    setContactFormHovered(true)
  };

  const notHoveringHandler = () => {
    setContactFormHovered(false)
  };

  const data = useStaticQuery(graphql`
    query ContactForm {
      contactFormJson {
        email {
          en
          es
        }
        firstName {
          en
          es
        }
        lastName {
          en
          es
        }
        letsTalk {
          en
          es
        }
        message {
          en
          es
        }
        orUseForm {
          en
          es
        }
        send {
          en
          es
        }
        tellUs {
          en
          es
        }
      }
    }
  `)

  return (
    <StyledContactForm>
      <StyledEmpty></StyledEmpty>
      <StyledText out={contactFormHovered}>
        <StyledLetsTalk>{data.contactFormJson.letsTalk[currentLang]}</StyledLetsTalk>
        <StyledEmailText>
          {data.contactFormJson.tellUs[currentLang]}<br/>
          <a href="mailto:contact@stigining.online" target="_blank" rel="noopener noreferrer">contact@stigining.online</a><br/>
          {data.contactFormJson.orUseForm[currentLang]}
        </StyledEmailText>
        <StyledForm
          autoComplete="off"
          onMouseEnter={hoveringHandler}
          onMouseLeave={notHoveringHandler}
          inside={hoveringHandler}
          outside={notHoveringHandler}
          about={about}
          name="contact"
          method="POST"
          action="https://formspree.io/f/moqpprwz" >
          <input type="hidden" name="form-name" value="contact" />

          <StyledNameGroup>
            <StyledItem>
              <StyledLabel for="firstName">{data.contactFormJson.firstName[currentLang]}</StyledLabel>
              <StyledInput type="text" name="firstName" id="firstName" />
              <StyledUnderline />
            </StyledItem>

            <StyledItem>
              <StyledLabel for="lastName">{data.contactFormJson.lastName[currentLang]}</StyledLabel>
              <StyledInput type="text" name="lastName" id="lastName" />
              <StyledUnderline />
            </StyledItem>
          </StyledNameGroup>


          <StyledItem>
            <StyledLabel for="email">{data.contactFormJson.email[currentLang]}</StyledLabel>
            <StyledInput type="email" name="_replytoc" id="email" />
            <StyledUnderline />
          </StyledItem>

          <StyledItem>
            <StyledLabel for="message">{data.contactFormJson.message[currentLang]}</StyledLabel>
            <StyledTextarea name="message" rows="7" id="message" />
            <StyledUnderline />
          </StyledItem>

          <StyledSendButton type="submit">{data.contactFormJson.send[currentLang]}</StyledSendButton>

        </StyledForm>
      </StyledText>

      <LetsTalk out={contactFormHovered} />
    </StyledContactForm>
  );
}

export default ContactForm;