import React from 'react';
import styled from 'styled-components';

const StyledText = styled.p`
  margin: 0 ${props => props.out ? "0%" : "-6%"} 0 0;
  align-self: center;
  font-family: ${props => props.theme.fonts.baskerville};
  font-weight: bold;
  font-size: 75px;
  width: 10%;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  color: ${props => props.theme.colors.green};
  z-index: 4;
  transition: margin 1s;

  /* Fallback: Set a background color. */
    background-color: red;

    /* Create the gradient. */
    background-image: ${props => props.theme.colors.blue};

    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

  @media (min-device-width : 768px) and (max-device-width : 1024px) {
    margin: 0 ${props => props.out ? "8%" : "2%"} 0 0;
  }

  @media (min-device-width : 320px) and (max-device-width : 667px) {
    display: none;
  }
`

const LetsTalk = ({out}) => {
  return (
    <StyledText out={out}>Let's Talk</StyledText>
  );
}

export default LetsTalk;
